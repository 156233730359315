<!-- 走访记录弹窗-->
<template>
  <div>
    <el-dialog
      :z-index="20"
      title="走访记录"
      :visible.sync="visible"
      width="60%"
      @close="closeModal"
      :close-on-click-modal="false"
    >
      <div class="visitConfig" @click="visitConfig" style="margin-bottom: 2%">
        + 添加走访记录
      </div>
      <a-list
        bordered
        v-for="(item, index) in interviewInfoList"
        :key="index"
        style="margin: 2% 0; border-radius: 10px"
      >
        <a-list-item style="margin-right: 0 !important">
          <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;">
            <a-descriptions-item label="现实状态" :span="1.5">
              {{ item.actualState_dictText }}
            </a-descriptions-item>
            <a-descriptions-item label="走访事项" :span="1.5">
              {{ item.interviewMatters }}
            </a-descriptions-item>
            <a-descriptions-item label="走访描述" :span="3">
              {{ item.interviewDescription ? item.interviewDescription : "--" }}
            </a-descriptions-item>
            <a-descriptions-item label="走访图片" :span="3">
              <div
                style="display: flex; flex-wrap: wrap"
                v-if="item.fileInfos.length > 0"
              >
                <el-image @click="showImg(file.url)"
                  v-for="(file, index) in item.fileInfos"
                  :key="index"
                  style="
                    width: 80px;
                    height: 80px;
                    margin: 0 10px;
                    border-radius: 10px;
                  "
                  :src="file.url"
                  fit="fit"
                  :preview-src-list="picList"
                >
                </el-image>
              </div>
              <div v-else class="empty">
                <span>暂无照片</span>
              </div>
            </a-descriptions-item>
          </a-descriptions>
        </a-list-item>
        <div
          slot="header"
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
          :headStyle="{ backgroundColor: '#FAFAFA' }"
        >
          <div style="display: flex; align-items: center; width: 30%">
            <img
              src="../../../../../../assets/images/interviewInfo.png"
              style="width: 30px; height: 30px"
              alt=""
            />
            <div
              style="
                font-size: 0.08rem;
                font-weight: bold;
                margin-left: 2%;
                color: #2d3138;
              "
            >
              走访对象：{{ residentName }}
            </div>
          </div>
          <div style="font-size: 0.05rem; color: #7d8da6">
            走访时间：{{ item.interviewTime }}
          </div>
        </div>
      </a-list>
      <a-pagination
        size="small"
        style="text-align: right; margin: 16px 0"
        show-size-changer
        :current="pagination.current"
        :showQuickJumper="pagination.showQuickJumper"
        :defaultCurrent="pagination.defaultCurrent"
        :hideOnSinglePage="pagination.hideOnSinglePage"
        :pageSize="pagination.pageSize"
        :defaultPageSize="pagination.defaultPageSize"
        :pageSizeOptions="pagination.pageSizeOptions"
        :showSizeChanger="pagination.showSizeChanger"
        :total="pagination.total"
        :show-total="pagination.showTotal"
        @change="paginationChange"
        @showSizeChange="showSizeChange"
      />
    </el-dialog>
    <!-- 新增走访记录弹窗 -->
    <add-visit-records
      :id="id"
      ref="addVisitor"
      @refsh="refsh"
    ></add-visit-records>
  </div>
</template>
<script>
import AddVisitRecords from "./addVisitRecords.vue";
import imagePreview from "./image-preview/imagePreview.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import api from "./api";
const searchForm = [
  {
    label: "关键字",
    type: "inputSearch",
    model: "searchText",
    options: { placeholder: "输入关键字进行搜索" },
    formItem: {},
    col: { span: 12 },
  },
];
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { AddVisitRecords, imagePreview },
  data() {
    //这里存放数据
    return {
      api,
      visible: false,
      id: "",
      businessId: "",
      fileInfos: [],
      columns: this.$ax.tools.initColumn([
        {
          title: "姓名",
          dataIndex: "residentName",
          ellipsis: true,
          width: 100,
        },
        {
          title: "身份证号",
          dataIndex: "identityCard",
          ellipsis: true,
          width: 150,
        },
        {
          title: "现住区域",
          dataIndex: "currentArea",
          ellipsis: true,
          width: 100,
        },
      ]),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      searchActions: [
        { name: "search", text: "查询" },
        { name: "reset", text: "重置" },
        { name: "add", text: "保存" },
      ],
      selectedRowKeys: [],
      dataSourceApis: api.residentList,
      dataSourceParams: {},
      interviewInfoList: [], //走访记录列表
      residentName: "", // 被访人姓名
      pagination: {
        current: 1,
        defaultCurrent: 1,
        pageSize: 2,
        defaultPageSize: 10,
        pageSizeOptions: ["1", "2", "10", "20"],
        showSizeChanger: true,
        total: 0,
        showTotal: (total) => `共${total}条`,
        hideOnSinglePage: false,
      },
      picList:[]
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      console.log(record, "137");
      this.visible = true;
      this.id = record.residentId;
      this.residentName = record.residentName;
      this.businessId = record.residentId;
      this.getInterviewInfo(record.residentId);
    },
    // 放大图片
    showImg(e){
      this.picList.push(e)
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false;
      this.$emit("receive", this.selectedRowKeys);
    },
    refsh() {
      this.getInterviewInfo(this.id);
    },
    // 新增走访
    visitConfig() {
      this.$refs.addVisitor.openModal();
    },
    // 根据id获取走访记录
    async getInterviewInfo(id) {
      const obj = {
        residentId: id,
        type: "1",
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize,
      };
      const res = await api.getInterviewInfoRecords(obj);
      console.log(res, "走访记录");
      this.interviewInfoList = res.data.records;
      this.pagination.total = res.data.total;
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
      console.log(selectedRowKeys, "95");
      this.selectedRowKeys = selectedRowKeys;
    },
    paginationChange(page, pageSize) {
      console.log(page, pageSize, "205");
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.getInterviewInfo(this.id);
    },
    showSizeChange(current, size) {
      console.log(current, size, "205");
      this.pagination.current = 1;
      this.pagination.pageSize = size;
      this.getInterviewInfo(this.id);
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

.visitConfig {
  width: 100%;
  height: 0.2rem;
  border-radius: 4px 4px 4px 4px;
  border: 1px dashed #556bff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #556bff;
  cursor: default;
}
/deep/.ant-list-bordered .ant-list-header {
  padding-left: 1% !important;
  background-color: #fafafa;
  border-radius: 10px 10px 0 0;
}
/deep/.ant-list-bordered .ant-list-item {
  padding-left: 0.5% !important;
}
/deep/.ant-descriptions-item-label {
  color: #7d8da6;
}
/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f4f4f5;
  border-radius: 10px;
  color: #ccc;
  background-color: #f4f4f5;
  width: 90px;
  height: 90px;
  cursor: pointer;
  margin-left: 10% !important;
}
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.ant-list-item {
  margin: 1% 0 0 24px !important;
}
</style>